<template>
  <el-dialog
      :visible.sync="visible"
      append-to-body
      :show-close="true"
      :center="true"
      :before-close="handleClose"
      title="选择企业"
      ref="workDialog"
      width="80%">
    <div>
      <div class="work_index_item">
        <div class="work_index_item">
          <ul>
            <a class="work_option" v-for="(item,index) in  companyList" :key="index"
               @click="changeCompanyUser(item.id)">
              <img :src="item.company.log_img">
              <div>{{ item.company.company_name }}</div>
            </a>
          </ul>
        </div>
        <div class="company_option_item">
          <ul>
            <a class="work_option" @click="addCompany">
              <img src="@/assets/image/company/chuangjian.png">
              <div>申请注册</div>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <company-dialog ref="communityDialogRef" @refresh="refresh"></company-dialog>
  </el-dialog>
</template>
<div class="shade"></div>

<script>
import companyDialog from "@/views/user/dialog/company-dialog.vue";
import {companyList} from "@/api/login/index";

export default {
  components: {
    companyDialog,
  },

  data() {
    return {
      visible: false,
      companyList: [],
      loginForm: {},
    };
  },

  methods: {
    addCompany() {
      this.$refs.communityDialogRef.init();
    },

    changeCompanyUser(id) {
      this.$emit('value-updated', id);

      this.visible = false;

    },


    handleClose() {
      this.visible = false;
    },
    refresh() {
      var that = this;
      companyList(that.loginForm).then((response) => {

        if (response.code == 200) {
          this.companyList = response.data.company_apps;
          return;
        }

        that.$message.error(response.msg ? response.msg : "请求错误");
      });

    },

    init(companyList, loginForm) {
      this.visible = true;
      this.companyList = companyList;
      this.loginForm = loginForm;
    },

  },
}
</script>

<style lang="scss" scoped>
.work_index {
  width: 1200px;
  margin: 0 auto;
}

.work_index_top {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bolder;
  border-bottom: 1px solid #CCCCCC;
}

.work_index_item {
  margin-top: 38px;
}

.work_index_item ul {
  margin-top: 23px;
  display: flex;
  justify-content: start;
  flex-flow: wrap;
}

.work_index_item ul li {
  width: 200px;
  margin: 10px;
}

.work_index_item ul .work_option {
  min-width: 200px;
  height: 60px;
  margin: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  display: flex;
}

.work_index_item ul .work_option img {
  height: 39px;
  width: 39px;
  margin-top: 11px;
  margin-left: 13px;
}

.work_index_item ul .work_option div {
  margin-left: 20px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  color: #181818;
}
</style>
