import request from '@/api/request'

//文件上传
export function uploadFile(data) {
    return request({
        url: '/web/file/Upload',
        method: 'POST',
        data
    })
}

export function mobilesms(data) {
    return request({
        url: '/api/sms',
        method: 'POST',
        data
    })
}
