<template>
  <div class="login">
    <div class="nav">兼职呗企业管理登录</div>
    <el-form ref="form" :model="formData" label-width="80px" :label-position="labelPosition">

      <div class="reg">
        <template>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="验证码登录" name="first">
              <el-form-item label="手机号">
                <el-input v-model="formData.mobile" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="短信验证码">
                <el-input v-model="formData.mobile_code" placeholder="请输入短信验证码"></el-input>
              </el-form-item>
              <p @click="sendSms" v-if="codeShow" style="cursor: pointer;text-align:right">发送验证码</p>
              <p v-else style="cursor: pointer;text-align:right">{{ count }}秒后重试</p>

              <el-button type="primary" @click="doLogin(1)">登录</el-button>

            </el-tab-pane>
            <el-tab-pane label="密码登录" name="second">
              <el-form-item label="手机号">
                <el-input v-model="formData.mobile" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="密码">
                <el-input v-model="formData.password" placeholder="请输入密码"></el-input>
              </el-form-item>

              <el-button type="primary" @click="doLogin(2)">登录</el-button>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </el-form>
    <div class="bottom">
      <template>
        <!-- `checked` 为 true 或 false -->
        <el-checkbox v-model="checked">《企业管理协议》&nbsp;&nbsp; 《企业管理协议》</el-checkbox>
      </template>
    </div>
    <company-app-dialog ref="companyAppDialogRef" @value-updated="handleValueUpdated"></company-app-dialog>
  </div>
</template>

<script>
import {companyList, login} from "@/api/login/index";
import {mobilesms,} from "@/api/common/index";
import companyAppDialog from "@/views/user/dialog/company-app-dialog.vue";

export default {
  name: "Login",
  components: {
    companyAppDialog,
  },
  data() {
    return {
      checked: false,
      activeName: "first",
      labelPosition: "left",
      formData: {
        company_user_id: 0,
        mobile: "18732855053",
        password: "000000",
        mobile_code: "52521",
        login_type: "",//登录类型 1手机号验证码登录 2 密码登录
        mobile_type: "3",
      },
      codeShow: true, //获取验证码倒计时
      timer: null, //定时器
      count: "", //倒计时数字

    };
  },
  methods: {
    handleClick(tab, event) {
      console.log("tab", tab);
      console.log("event", event);
    },
    handleValueUpdated(companyUserId) {
      var that = this;
      that.formData.company_user_id = companyUserId;

      login(that.formData).then((response) => {
        if (response.code == 200) {
          var userInfo = response.data;
          localStorage.setItem("token", userInfo.token);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          localStorage.setItem("company_name", userInfo.company_name);
          this.$router.push({name: "home", query: {}});

        } else {
          that.$message.error(response.msg ? response.msg : "请求错误");
        }
      });
    },
    companyAppDialog(companyAppList,form) {
      this.$refs.companyAppDialogRef.init(companyAppList,form);
    },

    sendSms() {
      if (this.formData.mobile == "") {
        this.$message({
          type: "warning",
          message: "手机号不能为空",
        });
        return;
      }
      const reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
      if (reg.test(this.formData.mobile)) {
        let data = {
          mobile: this.formData.mobile,
          mobile_type: 3
        };
        mobilesms(data).then((res) => {
          if (res.code == 200) {
            this.flag = false;
            this.$message({
              message: res.msg,
              type: "success",
            });
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.codeShow = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.codeShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.flag = true;
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "手机号格式错误",
        });
      }
    },

    doLogin(login_type) {
      var that = this;
      that.formData.login_type = login_type;

      companyList(that.formData).then((response) => {
        console.log(response.data);
        if (response.code == 200) {
          that.companyAppDialog(response.data.company_apps, that.formData);
          return;
        }

        that.$message.error(response.msg ? response.msg : "请求错误");
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  background-color: #4c71db;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav {
  color: white;
  font-size: 46px;
  letter-spacing: 20px;
  margin-bottom: 20px;
}

.reg,
.box {
  width: 420px;
}

/deep/ .el-form-item__label {
  width: 100px !important;
  color: white;
}

/deep/ .el-tabs__nav-wrap::after {
  width: auto;
}

/deep/ .el-tabs__header {
  margin-left: 160px;
}

/deep/ .el-form-item--small .el-form-item__content {
  margin-left: 100px !important;
}

/deep/ .el-tabs__item.is-active {
  color: white;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-checkbox__label {
  color: white;
}

.reg {
  margin-bottom: 15px;
}

.bottom {
  margin-left: 100px;
}

/deep/ .el-button--small {
  margin-left: 215px;
  margin-bottom: 15px;
}

p {
  margin-left: 205px;
  color: white;
  font-size: 12px;
}
</style>
