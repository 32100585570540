<template>
  <el-dialog
      :visible.sync="visible"
      append-to-body
      :show-close="true"
      :center="true"
      :before-close="handleClose"
      title="注册企业"
      width="80%"

  >
    <div>
      <el-form ref="form" :model="formData" :rules="rules"  label-position="top" label-width="100px;">
        <el-form-item label="企业名称" prop="company_name">
          <el-input type="text" v-model="formData.company_name" placeholder="企业名称"></el-input>
        </el-form-item>
        <el-form-item label="企业简称" prop="abbreviation">
          <el-input type="text" v-model="formData.abbreviation" placeholder="企业简称"></el-input>
        </el-form-item>
        <el-form-item label="企业logo" prop="log_img">
          <imageUpload v-model="formData.log_img"/>
        </el-form-item>
        <el-form-item label="营业执照" prop="img">
          <imageUpload v-model="formData.img"/>
        </el-form-item>
        <el-form-item label="负责人姓名" prop="contact">
          <el-input type="text" v-model="formData.contact" placeholder="负责人姓名"></el-input>
        </el-form-item>
        <el-form-item label="负责人手机号" prop="mobile">
          <el-input type="text" v-model="formData.mobile" placeholder="负责人手机号"></el-input>
        </el-form-item>
        <el-form-item label="登陆密码" prop="password">
          <el-input type="password" v-model="formData.password" placeholder="登陆密码"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input type="text" v-model="formData.email" placeholder="邮箱"></el-input>
        </el-form-item>
        <el-form-item label="推荐码" prop="recommender">
          <el-input type="text" v-model="formData.recommender" placeholder="推荐码"></el-input>
        </el-form-item>
        <el-form-item label="可申请主管" prop="type">
          <el-radio-group v-model="formData.is_ask" size="small" @change="changeAsk">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {v2Post} from "@/api/common/index";
import ImageUpload from '@/components/ImageUpload';

export default {
  components: {
    ImageUpload
  },

  data() {
    return {
      visible: false,
      companyDataList: [],
      formData: {
        company_name: '',
        abbreviation: '',
        log_img: '',
        img: '',
        contact: '',
        mobile: '',
        password: '',
        email: '',
        recommender: '',
        is_ask: 0,

      },
      rules: {
        company_name: [
          {required: true, message: '请输入企业名称', trigger: 'blur'}
        ],
        abbreviation: [
          {required: true, message: '请输入企业简称', trigger: 'blur'}
        ],
        log_img: [
          {required: true, message: '请输入企业logo', trigger: 'blur'}
        ],
        img: [
          {required: true, message: '请输入营业执照', trigger: 'blur'}
        ],
        contact: [
          {required: true, message: '请输入负责人姓名', trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: '请输入负责人手机号', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入登陆密码', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'}
        ],
        is_ask: [
          {required: true, message: '请输入可申请主管', trigger: 'change'}
        ],
      }
    };
  },


  methods: {
    changeAsk(value) {
      var _this = this;
      _this.formData.is_ask = value;
    },
    handleClose() {
      this.$refs["form"].resetFields();

      this.visible = false;

    },


    init() {
      this.visible = true;
    },

    submit() {
      var that = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          v2Post({
            company_store: {
              form:that.formData
            },
          }).then((response) => {
            if (response.code != 200) {
              that.$message.error(response.msg ? response.msg : "请求错误");
              return false;
            }

            this.$message({
              message: "注册成功",
              type: 'success'
            });
            that.$emit('refresh');
            that.visible = false;

          });
        }
      });

    },


  },


};
</script>

<style lang="scss" scoped>
.form-dialog{
  width: 80%;
  height: 400px;
  overflow: auto;
  position: absolute;
}
/deep/ .myWindow {
  border-radius: 15px;

  .el-button--primary {
    color: #FFF;
    background-color: #717ebb;
    border-color: #717ebb;
  }

}
</style>
